import React from 'react';
import CartFlyout from '../CartFlyout';
import Control from 'react-control';

export default React.createClass({
	open() {
		this.refs.main.classList.add('CartReminder--open');
		this.refs.main.classList.remove('CartReminder--close');
		this.fadeOut(10000);
		document.addEventListener('click', this.close);
	},
	close(e) {
		if (!e || (this.refs.main && !this.refs.main.contains(e.target))) {
			document.removeEventListener('click', this.close);
			clearTimeout(this.timeout);
			if (this.isMounted()) {
				this.refs.main.classList.remove('CartReminder--open');
				this.refs.main.classList.add('CartReminder--close');
			}
		}
	},
	fadeOut(ms) {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(()=> this.close(), ms);
	},
	componentWillUnmount() {
		this.close();
	},
	render() {
		return (
			<div ref='main' className='CartReminder' onMouseEnter={()=> clearTimeout(this.timeout)} onMouseLeave={()=> this.fadeOut(10000)}>
				<div className='CartReminder_top'>
					<Control className='CartReminder_close' onClick={()=> this.fadeOut(0)}>close [x]</Control>
				</div>
				<CartFlyout cart={this.props.cart}/>
			</div>
		);
	},
});
