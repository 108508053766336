import React from 'react';
import Error404 from '../Error404';
import request from 'arcdynamic-request';
import DocTitle from 'react-document-title';
import Editable from '../Editable';
import Contents from '../Contents';
import ReactiveWrapper from '../ReactiveWrapper';
import {TwoColumn, MainColumn, SideColumn} from '../TwoColumn';

function getPage(pageCode) {
	return request(arc.path.store, {
		service: 'arcimedes',
		action: 'open.datasource.table.Data.getData',
		params: ['code', 'KKAPAGES', {
			filter: [
				{
					text: pageCode,
					code: 'PAGECODE',
					type: 'exact',
				},
			],
			limit: {
				count: 1,
			},
		}],
	}).then((res)=>{
		return {
			page: res && res.success && res.data && res.data.length ? res.data[0] : false,
		}
	});
}

export default ReactiveWrapper(React.createClass({
	getInitialState() {
		return {
			isBusy: false,
		};
	},
	statics: {
		fetch(props) {
			return Promise.all([
				getPage(props.params.pageCode),
			]).catch(err => {
				throw err;
			});
		}
	},
	componentWillReceiveProps(nextProps) {
		if (this.props.params.pageCode !== nextProps.params.pageCode) {
			Promise.all([
				getPage(nextProps.params.pageCode),
			]).then(values => {
				if (this.isMounted()) {
					this.props.cursor.set(Object.assign({}, ...values));
				}
			}).catch(err => {
				throw err;
			});
		}
	},
	onSave(column, content) {
		if (this.state.isBusy) return;

		this.setState({isBusy: true});

		return request(arc.path.store, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.updateByTableCode',
			params: ['KKAPAGES', {
				value: [{
					[column]: content,
					PAGECODE: this.props.params.pageCode,
				}],
			}],
		}).then(()=>{
			// todo
			this.setState({isBusy: false});
		});
	},
	componentDidUpdate(prevProps) {
		if (this.props.page !== prevProps.page) {
			if (this.refs.contents) {
				this.refs.contents.refresh();
			}
		}
	},
	render() {
		let page = this.props.page;

		if (!page && !this.props.isAdmin) return <Error404/>;

		if (!page) {
			page = {
				TITLE: '',
				MAIN: '',
				ASIDE: '',
			}
		}

		return (
			<div>
				<DocTitle title={page.TITLE}/>
				<TwoColumn>
					<SideColumn>
					{
						!this.props.isAdmin ? <Contents selector='#main h1, #main h2' ref='contents'/> : null
					}
					</SideColumn>
					<MainColumn>
						<div id='main'>
							<Editable tag='h1' html={page.TITLE} enabled={this.props.isAdmin} whitelist={{}} onSave={(html, text)=> this.onSave('TITLE',text)}/>
							<hr/>
							<Editable html={page.MAIN} enabled={this.props.isAdmin} onSave={(html)=> this.onSave('MAIN',html)}/>
						</div>
					</MainColumn>
				</TwoColumn>
			</div>
		);
	}
}));
