import React from 'react';
import request from 'arcdynamic-request';
import ReactiveWrapper from '../ReactiveWrapper';
import url from 'url';

export default ReactiveWrapper(React.createClass({
	statics: {
		fetch(props) {
			return Promise.all([
				request(arc.path.store, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'KKAPROMO', {
						limit: {
							count: 1,
						},
					}],
				}).then((res)=>{
					return {
						page: res && res.success && res.data && res.data.length ? res.data[0] : false,
					}
				}),
				request(arc.path.store, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'KKAPROMOCODES', {
						filter: [{
							'text': props.code,
							'code': 'PROMO_CODE',
							'type': 'exact',
						}],
						limit: {
							count: 1,
						},
					}],
				}).then((res)=>{
					return {
						promo: res && res.success && res.data && res.data.length ? res.data[0] : false,
					}
				})
			]).catch(err => {
				throw err;
			});
		}
	},
	getInitialState() {
		return {
			style: {
				backgroundColor: this.props.page.BACKGROUNDCOLOR,
			}
		};
	},
	componentDidMount() {
		if (this.props.page.BACKGROUNDIMAGE) {
			const parsed = url.parse(this.props.page.BACKGROUNDIMAGE, true);
			parsed.query.w = parsed.query.w != null ? parsed.query.w : document.body.clientWidth;
			const src = url.format(parsed);
			
			this.setState({
				style: Object.assign({}, this.state.style, {
					backgroundImage: `url(${src})`,
				})
			})
		}
	},
	render() {
		return (
			<div className='PromoBanner' style={this.state.style}>
				{
					this.props.promo ? (
						<div className='PromoBanner_body' dangerouslySetInnerHTML={{__html: this.props.promo.MESSAGE}}/>
					) : (
						<div className='PromoBanner_body'>Promo Not Found.</div>
					)
				}
			</div>
		);
	},
}));
