import React from 'react';
import ReactiveWrapper from '../ReactiveWrapper';
import DocTitle from 'react-document-title';
import CategoryFilter from '../CategoryFilter';
import request from 'arcdynamic-request';
import Paginate from '../Paginate';
import Results from '../Results';
import queryMixin from '../../mixins/query';
import ProductList from '../ProductList';
import ProductGrid from '../ProductGrid';
import ProductFilter from '../ProductFilter';
import PromoBanner from '../PromoBanner';
import {TwoColumn, MainColumn, SideColumn} from '../TwoColumn';

const limitCount = 36; // or 24

function getItems(params, query) {
	const q = queryMixin.fillQuery(query);
	
	const filter = {
		search: q.q || null,
		categoryCode: params.categoryCode || null,
		excludeTagCode: [],
		tagValueCode: [],
	};

	if (params.promoCode) {
		filter.tagValueCode.push('promo,'+params.promoCode);
	} else {
		filter.excludeTagCode.push('promo');
	}

	if (!params.categoryCode) {
		filter.excludeTagCode.push('search-hide');
	}

	return request(arc.path.store, {
		service: 'cart',
		action: 'store.product.get',
		schema: '[name,sku,files,price,retailPrice,attributeValues]',
		options: {
			filter: filter,
			flag: {
				file: true,
				attributeValue: true,
			},
			limit: {
				count: limitCount,
				offset: (limitCount * q.page) - limitCount,
			},
			order: q.order || (params.categoryCode ? 'category-asc' : null),
			count: 2,
		},
	}, {
		expires: 1000*60*60,
	}).then((res)=>{
		return res && res.success ? {
			items: res.data,
			totalCount: res.count,
		} : {
			items: [],
			totalCount: false,
		};
	})
}

function getCategory(categoryCode) {
	if (categoryCode) {
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Category.get',
			params: [categoryCode],
		}, {
			expires: 1000*60*60,
		}).then((res)=>{
			return {
				mainCategory: res && res.success && res.data ? res.data : false,
			};
		});
	} else {
		return Promise.resolve().then(()=> {
			return {
				mainCategory: false,
			}
		});
	}
}

export default ReactiveWrapper(React.createClass({
	statics: {
		fetch(props) {
			const reqs = [];

			reqs.push(request(arc.path.store, {
				service: 'cart',
				action: 'store.Category.getTree',
				schema: '[id,name,code,files,children,_ext{excludeNav}]',
			}, {
				expires: 1000*60*60,
			}).then((res)=>{
				return {
					categories: res && res.success && res.data ? res.data : [],
				};
			}));

			reqs.push(getItems(props.params, props.location.query));
			reqs.push(getCategory(props.params.categoryCode));

			return Promise.all(reqs).catch(err => {
				throw err;
			});
		}
	},
	mixins: [
		queryMixin,
	],
	getDefaultProps() {
		return {
			view: 'grid', 
			items: [],
		};
	},
	componentWillReceiveProps(nextProps) {
		if (nextProps.params.categoryCode !== this.props.params.categoryCode) {
			getCategory(nextProps.params.categoryCode).then((data) => {
				if (this.isMounted()) {
					Object.keys(data).forEach(key => this.props.cursor.select(key).set(data[key]));
				}
			}).catch(err => {
				throw err;
			});
		}

		if (nextProps.location.query !== this.props.location.query || nextProps.params !== this.props.params) {
			getItems(nextProps.params, nextProps.location.query).then((data) => {
				if (this.isMounted()) {
					Object.keys(data).forEach(key => this.props.cursor.select(key).set(data[key]));
				}
			}).catch(err => {
				throw err;
			});
		}
	},
	render() {
		const q = this.fillQuery(this.props.location.query);
		const items = this.props.items;
		const mainCategory = this.props.mainCategory;

		return (
			<div className='ProductsRoot'>
				{
					mainCategory ? <DocTitle title={mainCategory.name}/> : null
				}
				{
					this.props.params.promoCode ? <PromoBanner cursor={this.props.cursor.select('promo')} code={this.props.params.promoCode}/> : null
				}
				<TwoColumn>
					<SideColumn>
						{
							this.props.params.promoCode ? null : (
								<ProductFilter title='Narrow by category'>
									<CategoryFilter categories={this.props.categories} categoryCode={this.props.params.categoryCode}/>
								</ProductFilter>
							)
						}
						{
							mainCategory && mainCategory.desc ? (
								<div dangerouslySetInnerHTML={{__html: mainCategory.desc}}/>
							) : null
						}
					</SideColumn>
					<MainColumn>
						{
							mainCategory ? (
								<div className='ProductsRoot_head'>
									<h1>{mainCategory.name}</h1>
									{
										mainCategory.descBrief ? <div dangerouslySetInnerHTML={{__html: mainCategory.descBrief}}/> : null
									}
								</div>
							) : null
						}
						<div className='ProductsRoot_nav'>
							<div className='ProductsRoot_nav_results'>
								<Results count={limitCount} page={q.page} itemCount={items.length} totalCount={this.props.totalCount} query={q.q} clearSearch={this.clearSearch}/>
							</div>
							<div className='ProductsRoot_nav_sort'>
								Display: <select aria-label='Display' defaultValue={this.props.view} onChange={e => this.props.cursor.select('view').set(e.target.value)}>
									<option value='grid'>Grid</option>
									<option value='list'>List</option>
								</select>
							</div>
							<div className='ProductsRoot_nav_sort'>
								Sort by: <select aria-label='Sort By' defaultValue={q.order} onChange={e => this.setOrder(e.target.value || undefined)}>
									<option value=''>Recommended</option>
									<option value='a-z'>Name A-Z</option>
									<option value='z-a'>Name Z-A</option>
									<option value='price-asc'>Price: low to high</option>
									<option value='price-desc'>Price: high to low</option>
								</select>
							</div>
						</div>
						{this.props.view === 'list' ? <ProductList products={items}/> : <ProductGrid products={items}/>}
						<div className='ProductsRoot_paginate'>
							<Paginate query={this.cleanQuery(q)} pathname={this.props.location.pathname} count={limitCount} page={q.page} itemCount={items.length} totalCount={this.props.totalCount}/>
						</div>
					</MainColumn>
				</TwoColumn>
			</div>
		);
	},
}));
