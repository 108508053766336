import React from 'react';
import Sticky from 'react-sticky-position';
import contents from '../../contents';

export default React.createClass({
	getInitialState() {
		return {
			tree: null,
			active: null,
		};
	},
	refresh() {
		this.destroyContents();

		const articles = document.body.querySelectorAll(this.props.selector);

		if (articles.length) {
			this.contents = contents({
				articles,
			});
			this.setState({tree: this.contents.tree()});
			this.changeListener = this.contents.eventEmitter().on('change', (data)=> {
				this.setState({active: data.current.article})
			});

			this.startPolling();
		}
	},
	stopPolling() {
		clearTimeout(this.pollingTimeout);
	},
	startPolling() {
		// Polling sucks, but not much else can be done here since we have 3rd party scripts loading content into the DOM.
		if (this.contents) {
			if (document.body.clientHeight !== this.clientHeight || document.body.clientWidth !== this.clientWidth) {
				this.clientHeight = document.body.clientHeight;
				this.clientWidth = document.body.clientWidth;
				this.contents.eventEmitter().trigger('resize');
			}
			this.pollingTimeout = setTimeout(this.startPolling, 150);
		}
	},
	destroyContents() {
		if (this.contents) {
			this.stopPolling();
			this.contents.eventEmitter().off(this.changeListener);
			this.contents = null;
			this.setState({
				tree: null,
				active: null,
			})
		}
	},
	componentDidMount() {
		this.refresh();
	},
	componentWillUnmount() {
		this.destroyContents();
	},
	renderList(list) {
		return (
			<ol className={'Contents_list Contents_list--'+list[0].level}>
				{
					list.map((el) => {
						return (
							<li key={el.id}>
								<a className={'Contents_link Contents_link--'+(el.level) + (el.element === this.state.active ? ' is-active' : '')} href={'#'+el.id}>{el.name}</a>
								{el.descendants.length ? this.renderList(el.descendants) : null}
							</li>
						)
					})
				}
			</ol>
		);
	},
	render() {
		if (!this.state.tree) return null;

		return (
			<Sticky>
				<div className='Contents'>
					{this.renderList(this.state.tree)}
				</div>
			</Sticky>
		);
	},
});
