import React from 'react';
import DocTitle from 'react-document-title';
import Header from '../Header';
import MainNav from '../MainNav';
import SiteAlert from '../SiteAlert';
import Footer from '../Footer';

export default React.createClass({
	handleKeyDown(e) {
		// Scheme to only show the focus outline when the user has used keyboard navigation to focus an element.
		if (e.key === 'Tab') {
			setTimeout(()=>{
				if (this.activeElement !== document.activeElement) {
					if (this.activeElement) {
						this.activeElement.removeAttribute('data-focused');
					}
					if (document.activeElement) {
						this.activeElement = document.activeElement;
						this.activeElement.setAttribute('data-focused', true);
					}
				}
			}, 0);
		}
	},
	render() {
		return (
			<div className='Root' onKeyDown={this.handleKeyDown}>
				<DocTitle title='Kosher Kare Anywhere'/>
				<SiteAlert/>
				<Header user={this.props.user} cart={this.props.cart}/>
				<MainNav cursor={this.props.tree.select('nav')} search={this.props.location.query.q}/>
				{React.cloneElement(this.props.children, {cursor: this.props.tree.select('body')})}
				<Footer/>
			</div>
		);
	},
});