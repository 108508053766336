import tree from './tree';
import request from 'arcdynamic-request';

export default {
	getUser() {
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Customer.get',
			schema: '{typeCode,name}',
		}).then((res)=>{
			tree.select('user').set(res && res.success ? res.data : false);
			return res;
		});
	},
};
