import React from 'react';
import Control from 'react-control';
import Scribe from 'scribe-editor';
import sanitizer from 'scribe-plugin-sanitizer';
import toolbar from 'scribe-plugin-toolbar';
import convertToSemantic from 'scribe-plugin-formatter-html-ensure-semantic-elements';
import newlinesToHTML from 'scribe-plugin-formatter-plain-text-convert-new-lines-to-html';
import smartLists from 'scribe-plugin-smart-lists';
import headingCmd from 'scribe-plugin-heading-command';
import linkCmd from 'scribe-plugin-link-prompt-command';
import unlinkCmd from 'scribe-plugin-intelligent-unlink-command';
import blockquoteCmd from 'scribe-plugin-blockquote-command';
import imagePrompt from '../../scribe-plugin-img-prompt-command';
import whitelist from '../../whitelist';

import Icon from '../Icon';
import svgItalic from '../../svgs/italic.svg';
import svgStrikethrough from '../../svgs/strikethrough.svg';
import svgLink from '../../svgs/link.svg';
import svgUnlink from '../../svgs/unlink.svg';
import svgListUl from '../../svgs/list-ul.svg';
import svgListOl from '../../svgs/list-ol.svg';
import svgQuoteLeft from '../../svgs/quote-left.svg';
import svgPhoto from '../../svgs/photo.svg';

export default React.createClass({
	getDefaultProps() {
		return {
			whitelist: whitelist,
		};
	},
	init(node) {
		const wl = this.props.whitelist;
		const scribe = new Scribe(node);

		scribe.use(sanitizer({
			tags: wl,
		}));

		if (wl.h1) {
			scribe.use(headingCmd(1));
		}
		if (wl.h2) {
			scribe.use(headingCmd(2));
		}
		if (wl.h3) {
			scribe.use(headingCmd(3));
		}
		if (wl.h4) {
			scribe.use(headingCmd(4));
		}
		if (wl.h5) {
			scribe.use(headingCmd(5));
		}
		if (wl.a) {
			scribe.use(linkCmd());
			scribe.use(unlinkCmd());
		}
		if (wl.ul) {
			scribe.use(smartLists());
		}
		if (wl.blockquote) {
			scribe.use(blockquoteCmd());
		}
		if (wl.img) {
			scribe.use(imagePrompt());
		}
		scribe.use(newlinesToHTML());
		scribe.use(convertToSemantic());
		scribe.use(toolbar(this.refs.main));
	},
	render() {
		const wl = this.props.whitelist;

		return (
			<div className='Editor' ref='main'>
				{
					wl.h1 ? <Control className='Editor_btn tooltip'  data-command-name='h1' aria-label='Heading 1'>H1</Control> : null
				}
				{
					wl.h2 ? <Control className='Editor_btn tooltip'  data-command-name='h2' aria-label='Heading 2'>H2</Control> : null
				}
				{
					wl.h3 ? <Control className='Editor_btn tooltip'  data-command-name='h3' aria-label='Heading 3'>H3</Control> : null
				}
				{
					wl.h4 ? <Control className='Editor_btn tooltip'  data-command-name='h4' aria-label='Heading 4'>H4</Control> : null
				}
				{
					wl.h5 ? <Control className='Editor_btn tooltip'  data-command-name='h5' aria-label='Heading 5'>H5</Control> : null
				}
				{
					wl.b ? <Control className='Editor_btn tooltip'  data-command-name='bold' aria-label='Bold'>B</Control> : null
				}
				{
					wl.i ? <Control className='Editor_btn tooltip'  data-command-name='italic' aria-label='Italic'><Icon svg={svgItalic}/></Control> : null
				}
				{
					wl.strike ? <Control className='Editor_btn tooltip'  data-command-name='strikethrough' aria-label='Strikethrough'><Icon svg={svgStrikethrough}/></Control> : null
				}
				{
					wl.a ? <Control className='Editor_btn tooltip'  data-command-name='linkPrompt' aria-label='Insert/edit link'><Icon svg={svgLink}/></Control> : null
				}
				{
					wl.a ? <Control className='Editor_btn tooltip'  data-command-name='unlink' aria-label='Remove link'><Icon svg={svgUnlink}/></Control> : null
				}
				{
					wl.ul ? <Control className='Editor_btn tooltip'  data-command-name='insertUnorderedList' aria-label='Bulleted list'><Icon svg={svgListUl}/></Control> : null
				}
				{
					wl.ul ? <Control className='Editor_btn tooltip'  data-command-name='insertOrderedList' aria-label='Numbered list'><Icon svg={svgListOl}/></Control> : null
				}
				{
					wl.ul ? <Control className='Editor_btn tooltip'  data-command-name='blockquote' aria-label='Blockquote'><Icon svg={svgQuoteLeft}/></Control> : null
				}
				{
					wl.img ? <Control className='Editor_btn tooltip'  data-command-name='imagePrompt' aria-label='Insert image'><Icon svg={svgPhoto}/></Control> : null
				}
				{
					wl.hr ? <Control className='Editor_btn tooltip'  data-command-name='insertHorizontalRule' aria-label='Insert horizontal line'>---</Control> : null
				}
				<Control className='Editor_save' onClick={this.props.onSave}>
					save
				</Control>
			</div>
		);
	},
});
