import React from 'react';
import Control from 'react-control';
import imgSrc from 'arcdynamic-resize';

export default React.createClass({
	getDefaultProps() {
		return {
			fullWidth: 450,
			thumbWidth: 70,
		};
	},
	getInitialState() {
		return {
			selected: this.props.images.length ? this.props.images[0] : null,
		};
	},
	componentWillReceiveProps(nextProps) {
		if(!this.state.selected){
			if(nextProps.images.length){
				this.setState({
					selected: nextProps.images[0]
				});
			}
		} else if (nextProps.images.every(x => x.id !== this.state.selected.id)) {
			this.setState({
				selected: nextProps.images.length ? nextProps.images[0] : null,
			})
		}
	},
	render() {
		return (
			<div className='ProductPhotos'>
				<div className='ProductPhotos_main'>
					<img src={this.state.selected ? imgSrc(this.state.selected.url, this.props.fullWidth) : null} width={this.props.fullWidth}/>
				</div>
				{
					this.props.images.length > 1 ? (
						<div className='ProductPhotos_thumbs'>
							{
								this.props.images.map(el => (
									<Control onClick={()=> this.setState({selected: el})} onMouseOver={()=> {
										document.createElement('img').src = imgSrc(el.url, this.props.fullWidth);
									}} className={'ProductPhotos_thumb '+(el.id === this.state.selected.id ? 'is-active' : '')} key={el.id}>
										<img src={imgSrc(el.url, this.props.thumbWidth)} width={this.props.thumbWidth}/>
									</Control>
								))
							}
						</div>
					) : null
				}
			</div>
		);
	},
});
