import React from 'react';
import Editor from '../Editor';
import Sticky from 'react-sticky-position';
import shallowEqual from 'react-pure-render/shallowEqual';

export default React.createClass({
	getDefaultProps() {
		return {
			html: '',
			tag: 'div',
			enabled: true,
		};
	},
	componentDidMount() {
		if (this.props.enabled) {
			this.initToolbar();
		}
	},
	initToolbar() {
		this.refs.toolbar.init(this.refs.content);
	},
	handleSave() {
		const content = this.refs.content;
		this.props.onSave(content.innerHTML, content.innerText);
	},
	shouldComponentUpdate(nextProps) {
		return !shallowEqual(nextProps, this.props);
	},
	componentDidUpdate(prevProps) {
		if (this.props.enabled && !prevProps.enabled) {
			this.initToolbar();
		}
	},
	render() {
		const content = React.createElement(this.props.tag, {
			dangerouslySetInnerHTML: {__html: this.props.html},
			ref: 'content',
		});

		if (!this.props.enabled) return content;

		return (
			<div className='Editable'>
				<Sticky>
					<Editor ref='toolbar' whitelist={this.props.whitelist || undefined} onSave={this.handleSave}/>
				</Sticky>
				{content}
			</div>
		);
	},
});