import 'normalize.css/normalize.css';
import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import tree from './tree';
import {createHistory} from 'history';
import auth from './auth';
import cart from './cart';
import exceptionLogger from './exception-logger';

const history = createHistory();

history.listen((location)=> {
	if (window && window.ga) {
		window.ga('send', 'pageview', location.pathname+location.search);
	}

	if (window) {
		auth.getUser();
		cart.getCart();
	}
});

// Catching unhandled exceptions natively 
window.addEventListener("unhandledrejection", err => {
    throw err.detail.reason;
});

window.addEventListener('error', exceptionLogger);

let fn;

tree.off('update', fn);
fn = (()=> ReactDOM.render(React.cloneElement(Routes(history, tree)), document.getElementById('react-root')));
tree.on('update', fn);
tree.commit();