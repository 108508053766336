import tree from './tree';
import request from 'arcdynamic-request';

export default {
	getCart() {
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Cart.get',
		}).then((res)=>{
			tree.select('cart').set(res && res.success && res.data ? res.data : null);
			return res;
		})
	},
	getCount() {
		const cart = tree.select('cart').get();

		if (cart && cart.orders) {
			return Object.keys(cart.orders).map(key => cart.orders[key]).reduce((a, b) => {
				return a + b.products.reduce((a, b) => {
					return a + b.quantity;
				}, 0);
			}, 0);
		} else {
			return null;
		}
	},
	addProduct({orderIndex, id, quantity} = {}) {
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Cart.addProduct',
			params: [orderIndex || null, id, quantity],
		}).then((res)=>{
			tree.select('cart').set(res.data);
			return res;
		})
	},
	addSubscription({orderIndex, id, quantity, subscriptionPeriodId} = {}) {
		return request(arc.path.store, {
			service: 'cart',
			action: 'store.Cart.subscription.addProduct',
			params: [orderIndex || null, id, quantity, subscriptionPeriodId],
			options: {
				autoRenewStatusCode: 'inactive'
			}
		}).then((res)=>{
			tree.select('cart').set(res.data);
			return res;
		})
	},
};
