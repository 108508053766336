import React from 'react';
import Control from 'react-control';
import Icon from '../Icon';
import svgPlus from '../../svgs/plus.svg';
import svgMinus from '../../svgs/minus.svg';

export default React.createClass({
	getInitialState() {
		return {
			isOpen: false,
		};
	},
	render() {
		return (
			<div className={'ProductFilter'+(this.state.isOpen ? ' is-open' : '')}>
				<Control className='ProductFilter_head' onClick={()=> this.setState({isOpen: !this.state.isOpen})}>
					<div className='ProductFilter_title'>{this.props.title}</div>
					<div className='ProductFilter_toggle'><Icon svg={this.state.isOpen ? svgMinus : svgPlus}/></div>
				</Control>
				<div className='ProductFilter_body'>
					{this.props.children}
				</div>
			</div>
		);
	},
});