import React from 'react';
import ReactiveWrapper from '../ReactiveWrapper';
import SquareImage from '../SquareImage';
import Link from '../Link';
import request from 'arcdynamic-request';
import Currency from '../Currency';

export default ReactiveWrapper(React.createClass({
	statics: {
		fetch(props) {
			return Promise.all([
				request(arc.path.store, {
					service: 'cart',
					action: 'store.product.get',
					schema: '[name,sku,files,price,retailPrice,attributeValues]',
					options: {
						filter: {
							categoryCode: props.categoryCode || null,
						},
						limit: {
							count: props.count,
						},
						flag: {
							file: true,
							attributeValue: true,
						},
					},
				}).then((res)=>{
					return {
						products: res && res.success ? res.data : null
					};
				})
			]).catch(err => {
				throw err;
			});
		}
	},
	render() {
		if (!this.props.products || !this.props.products.length) return null;

		return (
			<div className='TopSellers'>
				{
					this.props.products.map(el => {
						const thumb = el.files.filter(el => el.type === 'image').shift();

						return (
							<Link className='TopSellers_item' to={arc.path.base+'product/'+el.sku} key={el.sku}>
								<SquareImage src={thumb ? thumb.url : null}/>
								<div>{el.name}</div>
								{
									el.attributeValues ? el.attributeValues.map(x => <div key={x.attributeId}>{x.name}</div>) : null
								}
								<Currency className='TopSellers_price'>{el.price}</Currency> {el.retailPrice ? <Currency className='TopSellers_retail'>{el.retailPrice}</Currency> : null}
							</Link>
						);
					})
				}
			</div>
		);
	},
}));
