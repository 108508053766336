import React from 'react';
import DocTitle from 'react-document-title';
import {TwoColumn, MainColumn} from '../TwoColumn';

export default React.createClass({
	render() {
		return (
			<TwoColumn>
				<MainColumn>
					<DocTitle title='Page Not Found'/>
					<h1>Page Not Found</h1>
					<p>The page you are looking for does not exist, or the URL has changed.</p>
				</MainColumn>
			</TwoColumn>
		);
	},
});