import React from 'react';
import Currency from '../Currency';
import numberToLocaleString from '../../numberToLocaleString';

export default React.createClass({
	renderEmpty() {
		return (
			<div className='CartFlyout'>
				<div className='CartFlyout_empty'>
					Your shopping cart is empty.
				</div>
			</div>
		);
	},
	render() {
		if (!this.props.cart) {
			return this.renderEmpty();
		}

		const orders = this.props.cart.orders ? Object.keys(this.props.cart.orders).map(key => this.props.cart.orders[key]).filter(el => el.products.length) : []

		if (!orders.length) {
			return this.renderEmpty();
		}

		return (
			<div className='CartFlyout'>
				{
					orders.map(el => {
						const count = el.products.reduce((a, b) => { return a+b.quantity}, 0);

						return (
							<div key={el.orderIndex} className='CartFlyout_item'>
								<div className='CartFlyout_item_label'>
									{numberToLocaleString(count)} {count !== 1 ? 'items' : 'item'} going to {el.name ? el.name : 'You'}
								</div>
								<div className='CartFlyout_item_price'>
									<Currency>{el.total}</Currency>
								</div>
							</div>
						);
					})
				}
				<div className='CartFlyout_total'>
					<div className='CartFlyout_item'>
						<div className='CartFlyout_item_label'>
							Total
						</div>
						<div className='CartFlyout_item_price CartFlyout_item_price--total'>
							<Currency>{this.props.cart.total}</Currency>
						</div>
					</div>
				</div>
				<div className='CartFlyout_nav'>
					<a className='CartFlyout_btn CartFlyout_btn--cart' href={arc.path.cart}>View Cart</a> <a className='CartFlyout_btn CartFlyout_btn--checkout' href={arc.path.cart+'checkout'}>Checkout</a>
				</div>
			</div>
		);
	},
});
