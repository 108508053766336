import React from 'react';
import Link from '../Link';
import HeaderSearch from '../HeaderSearch';

export default React.createClass({
	render() {
		return (
			<div className='BigNav'>
				<Link className='BigNav_home' to={arc.path.base}>
					<img alt='Home' src={arc.path.base+'img/logo.svg'} />
				</Link>
				{/*<div className='BigNav_main'>
					<ul className='BigNav_nav'>
						<li className='BigNav_nav_section'>
							<Link className='BigNav_nav_label' to={arc.path.base+'browse/'}>Order / Sign Up</Link>
						</li>
						<li className='BigNav_nav_section'>
							<Link className='BigNav_nav_label' to={arc.path.base+'about/'}>What Is Kosher Kare Anywhere?</Link>
						</li>
						<li className='BigNav_nav_section'>
							<Link className='BigNav_nav_label' to={arc.path.base+'contact/'}>Contact Us</Link>
						</li>
					</ul>
					<div className='BigNav_search'>
						<HeaderSearch placeholder='Search the good stuff' search={this.props.search}/>
					</div>
				</div>*/}
			</div>
		);
	},
});
