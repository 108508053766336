import React from 'react';
import {History} from 'react-router';
import Control from 'react-control';
import Icon from '../Icon';
import svgSearch from '../../svgs/search.svg';

export default React.createClass({
	mixins: [
		History,
	],
	componentWillUpdate(nextProps) {
		const nextQuery = nextProps.search

		if (nextQuery !== this.props.search) {
			this.refs.q.value = nextQuery || '';
		}
	},
	handleSubmit(e) {
		e.preventDefault();

		if (this.props.onSubmit) {
			this.props.onSubmit();
		}
		
		this.history.pushState(null, arc.path.base+'browse/', {q: this.refs.q.value});
	},
	render() {
		return (
			<form className='HeaderSearch' onSubmit={this.handleSubmit}>
				<input ref='q' type='text' placeholder={this.props.placeholder} autoFocus={this.props.autoFocus} defaultValue={this.props.search}/>
				<Control onClick={this.handleSubmit} className='HeaderSearch_btn'><Icon svg={svgSearch}/></Control>
			</form>
		);
	},
});