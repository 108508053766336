import React from 'react';
import Field from '../Field';
import request from 'arcdynamic-request';
import report from '../../report';
import parseForm from 'form-to-object';
import Alert from '../Alert';

export default React.createClass({
	getInitialState(){
		return {};
	},
	handleSubmit(e) {
		e.preventDefault();

		this.setState({isBusy: true});
		const formData = parseForm(e.currentTarget);

		formData.message = `${formData.message}\n\n-----------------------------\n\n${report(window)}`;

		request(arc.path.store, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.updateData',
			params: ['code','KKACONTACTFORM'],
			options: {
				value: [
					{
						TYPE: 'contactForm',
						NAME: formData.name,
						EMAIL: formData.email,
						SUBJECT: formData.subject,
						MESSAGE: formData.message,
					}
				]
			},
		}).then(res =>{
			if (!this.isMounted()) return;

			if (res && res.success) {
				this.setState({
					isBusy: null,
					isSubmitted: true,
				});
			} else {
				this.setState({
					isBusy: null,
					alertMessage: res && res.message ? res.message : <span>Sorry, there was a problem submitting your feedback. Please email your feedback to <a href="mailto:orders@kosherkare.org">orders@kosherkare.org</a></span>,
				});
			}
		});
	},
	render() {
		return (
				this.state.isSubmitted ? (
					<div>
						<h2>Thanks!</h2>
						<p>We appreciate your feedback.</p>
					</div>
				) : (
				<form onSubmit={this.handleSubmit}>
					<Field label='Name' required>
						<input name='name' type='text' required/>
					</Field>
					<Field label='Email' required>
						<input name='email' type='email' required/>
					</Field>
					<Field label='Subject' required>
						<input name='subject' type='text' required/>
					</Field>
					<Field label='Message' required>
						<textarea name='message' required/>
					</Field>
					<button data-is-busy={this.state.isBusy}>Submit</button>
					{
						this.state.alertMessage ? <div className='Feedback_form_alert'><Alert type='error'>{this.state.alertMessage}</Alert></div> : null
					}
				</form>
				)
		);
	}
});