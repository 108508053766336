import React from 'react';
import Control from 'react-control';
import svgTimesCircle from '../../svgs/times-circle.svg';
import Icon from '../Icon';

export default ({onClick, children})=>(
	<Control className='TagButton' onClick={onClick}>
		<span className='TagButton_term'>{children}</span>
		<span className='TagButton_icon'><Icon svg={svgTimesCircle}/></span>
	</Control>
);