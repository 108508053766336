import React from 'react';
import request from 'arcdynamic-request';
import getOrdinal from '../../getOrdinal';
import months from '../../months';
import Icon from '../Icon';
import svgClock from '../../svgs/clock-o.svg';

export default React.createClass({
	getInitialState() {
		return {
			deliveryDate: null,
		};
	},
	componentDidMount() {
		request(arc.path.store, {
			service: 'cart',
			action: 'store.Product.getEarliestPossibleDeliveryDate',
			params: [this.props.productId],
		}).then((res)=>{
			if (!this.isMounted()) return;

			if (res && res.success && res.data && res.data.deliveryDate) {
				this.setState({
					deliveryDate: res.data.deliveryDate,
				});
			}
		});
	},
	render() {
		if (!this.state.deliveryDate) return null;

		// todo: deliveryDate being converted to UTC, which may be wrong.
		const date = new Date(this.state.deliveryDate);

		return (
			<div className='EarliestDelivery'>
				<span className='EarliestDelivery_icon'>
					<Icon svg={svgClock}/>
				</span>
				<div>Can be delivered as early as <span className='EarliestDelivery_date'>
				{months[date.getUTCMonth()]} {getOrdinal(date.getUTCDate())}
				</span>!</div>
			</div>
		);
	},
});
