import React from 'react';
import DocTitle from 'react-document-title';
import request from 'arcdynamic-request';
import {History} from 'react-router';
import ReactiveWrapper from '../ReactiveWrapper';
import url from 'url';

export default ReactiveWrapper(React.createClass({
	statics: {
		fetch() {
			return Promise.all([
				request(arc.path.store, {
					service: 'arcimedes',
					action: 'open.datasource.table.Data.getData',
					params: ['code', 'KKAPROMO', {
						limit: {
							count: 1,
						},
					}],
				}).then((res)=>{
					return {
						page: res && res.success && res.data && res.data.length ? res.data[0] : false,
					}
				})
			]).catch(err => {
				throw err;
			});
		}
	},
	mixins: [
		History,
	],
	handleSubmit(e) {
		e.preventDefault();
		this.history.pushState(null, arc.path.base+'promo/'+this.refs.promoCode.value);
	},
	getInitialState() {
		return {
			style: {
				backgroundColor: this.props.page.BACKGROUNDCOLOR,
			}
		};
	},
	componentDidMount() {
		if (this.props.page.BACKGROUNDIMAGE) {
			const parsed = url.parse(this.props.page.BACKGROUNDIMAGE, true);
			parsed.query.w = parsed.query.w != null ? parsed.query.w : document.body.clientWidth;
			const src = url.format(parsed);
			
			this.setState({
				style: Object.assign({}, this.state.style, {
					backgroundImage: `url(${src})`,
				})
			})
		}
	},
	render() {
		const page = this.props.page;

		return (
			<div className='Promo' style={this.state.style}>
				<DocTitle title={page.TITLE}/>
				<div className='Promo_body'>
					<h2>{page.HEADING}</h2>
					<form onSubmit={this.handleSubmit}>
						<input autoFocus={true} ref='promoCode' type='text' placeholder='Enter promotional code'/>
						<button>Submit</button>
					</form>
				</div>
			</div>
		);
	},
}));
