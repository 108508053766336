import React from 'react';
import request from 'arcdynamic-request';
import Control from 'react-control';
import Icon from '../Icon';
import svgClose from '../../svgs/close.svg';
import svgExclamationTriangle from '../../svgs/exclamation-triangle.svg';

export default React.createClass({
	componentDidMount() {
		request(arc.path.store, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.getData',
			params: ['code', 'KKAALERTS', {
				limit: {
					count: 10,
				},
				filter: [
					{
						text: '1',
						code: 'ACTIVE',
						type: 'exact',
					},
				],
			}],
		}).then((res)=>{
			if (!this.isMounted()) return;

			if (res && res.success && res.data.length) {
				this.setState({
					alerts: res.data
				});
			}
		})
	},
	getInitialState() {
		return {
			isOpen: true,
			alerts: false,
		};
	},
	render() {
		return this.state.alerts && this.state.alerts.length && this.state.isOpen ? (
			<div className='SiteAlert'>
				<div className='SiteAlert_body'>
					<div className='SiteAlert_list'>
						{
							this.state.alerts.map((el,i) => {
								return (
									<div key={i} className='SiteAlert_item'>
										<span className='SiteAlert_icon'><Icon svg={svgExclamationTriangle}/></span>
										<div className='SiteAlert_desc' dangerouslySetInnerHTML={{__html: el.DESCRIPTION}}/>
									</div>
								);
							})
						}
					</div>
					<Control className='SiteAlert_close' onClick={()=> this.setState({isOpen: false})}><Icon svg={svgClose}/></Control>
				</div>
			</div>
		) : null;
	},
});
