import React from 'react';
import imgSrc from 'arcdynamic-resize';

export default React.createClass({
	getInitialState() {
		return {
			loaded: false,
			error: this.props.src ? false : true,
		};
	},
	init(src) {
		if (!src) {
			this.setState({
				loaded: false,
				error: true,
			});
			return;
		}

		this.setState({
			error: false,
			loaded: false,
		}, ()=>{
			const img = new Image();
			const resampled = imgSrc(src, this.refs.main.clientWidth);

			img.src = resampled;

			img.onerror = ()=>{
				img.onerror = null;
				if (this.isMounted()) {
					this.setState({
						loaded: false,
						error: true,
					});
				}
			}

			img.onload = ()=> {
				img.onload = null;
				if (this.isMounted()) {
					this.setState({
						loaded: resampled,
						error: false,
					})
				}
			}
		});
	},
	componentDidMount() {
		this.init(this.props.src);
	},
	componentWillUpdate(nextProps) {
		if (nextProps.src !== this.props.src) {
			this.init(nextProps.src);
		}
	},
	render() {
		return (
			<div ref='main' className={'SquareImage'+(this.state.error ? ' SquareImage--error' : (this.state.loaded ? '': ' SquareImage--loading'))} style={this.state.loaded ? {backgroundImage: `url(${this.state.loaded})`} : null}/>
		);
	}
});
