import React from 'react';
import Link from '../Link';

export default React.createClass({
	render() {
		return (
			<div className='Footer'>
				<div className='Footer_body'>
					{/*<ul>
						<li><Link to={arc.path.base+'about'}>About</Link></li>
						<li><Link to={arc.path.base+'contact'}>Contact</Link></li>
					</ul>*/}
				</div>
			</div>
		);
	},
});
