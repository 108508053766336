import React from 'react';
import Currency from '../Currency';
import imgSrc from 'arcdynamic-resize';

export default React.createClass({
	propTypes: {
		products: React.PropTypes.array.isRequired,
	},
	getDefaultProps() {
		return {
			width: 75,
		};
	},
	getIds() {
		return this.props.products.filter(el => this.refs[el.id].checked).map(el => el.id);
	},
	reset() {
		this.props.products.forEach(el => this.refs[el.id].checked = false);
	},
	render() {
		return (
			<div className='AddOns'>
				{
					this.props.products.map(el => {
						const image = el.files.filter(x => x.type === 'image').shift() || null;
						const src = image ? imgSrc(image.url, this.props.width) : null;

						return (
							<label key={el.id} className='AddOns_item'>
								<div className='AddOns_item_box'>
									<input ref={el.id} type='checkbox' name={el.id} value={el.id}/>
								</div>
								<img width={this.props.width} height={this.props.width} src={src}/>
								<div className='AddOns_item_body'>
									<strong>Add {el.name}</strong> <em>+ <Currency>{el.price}</Currency></em>
								</div>
							</label>
						);
					})
				}
			</div>
		);
	},
});
