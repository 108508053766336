import request from 'arcdynamic-request';
import React from 'react';
import DocTitle from 'react-document-title';
import Error404 from '../Error404';
import Contents from '../Contents';
import TopSellers from '../TopSellers';
import ReactiveWrapper from '../ReactiveWrapper';
import AddProduct from '../AddProduct';
import {History} from 'react-router';
import {TwoColumn, MainColumn, SideColumn} from '../TwoColumn';
import Currency from '../Currency';
import ProductPhotos from '../ProductPhotos';
import FlavorProduct from '../FlavorProduct';
import ProductAttributes from '../ProductAttributes';
import EarliestDelivery from '../EarliestDelivery';
import Alert from '../Alert';

export default ReactiveWrapper(React.createClass({
	statics: {
		fetch(props) {
			return Promise.all([
				request(arc.path.store, {
					service: 'cart',
					action: 'store.product.get',
					schema: '[id,name,sku,desc,price,files,attributeValues]',
					options: {
						filter: {
							tagValueCode: ['add-on,'+props.params.sku]
						},
						flag: {
							file: true,
							attributeValue: true,
						},
					},
				}).then((res)=>{
					return {
						addOns: res && res.success ? res.data : null,
					};
				}),
				request(arc.path.store, {
					service: 'cart',
					action: 'store.product.get',
					schema: '[id,name,sku,desc,price,retailPrice,files,attributeValues,subscriptionPeriods,quantityStatusCode,productBase{sku},_ext{highlightTitle}]',
					options: {
						filter: {
							siblingSku: props.params.sku,
						},
						limit: {
							count: 50,
						},
						flag: {
							file: true,
							attributeValue: true,
							subscriptionPeriod: true,
							productBase: true,
						},
					},
				}).then((res)=>{
					const products = res && res.success ? res.data : [];
					const variableProducts = [];
					const flavorIndex = {};

					products.forEach(el => {
						const flavor = el.attributeValues.filter(el => el.attribute.name === 'flavor').pop();

						if (flavor) {
							flavorIndex[flavor.name] = el;
						} else {
							variableProducts.push(el);
						}
					});

					const variables = Object.keys(flavorIndex).sort().map(key => flavorIndex[key]).concat(variableProducts);

					return {
						products,
						variableProducts: variables,
					};
				}),
			]).catch(err => {
				throw err;
			});
		}
	},
	mixins: [
		History,
	],
	componentWillReceiveProps(nextProps) {
		if (nextProps.query !== this.props.query || nextProps.params !== this.props.params) {
			this.constructor.fetch(nextProps).then(values => {
				if (this.isMounted()) {
					values.forEach(obj => {
						Object.keys(obj).forEach(key => {
							this.props.cursor.select(key).set(obj[key])
						});
					});
				}
			});
		}
	},
	selectProduct(e) {
		const sku = e.currentTarget.value;
		this.history.replaceState(null, arc.path.base+'product/'+sku);
	},
	getSelected() {
		if (!this.props.products) return null;
		return this.props.products.filter(el => el.sku == this.props.params.sku).pop();
	},
	render() {
		if (!this.props.products) return null;
		
		const selected = this.getSelected();

		if (!selected) return <Error404/>;

		const defaultAttributeName = selected.attributeValues.length ? selected.attributeValues[0].attribute.name : '';

		const images = selected.files.filter(el => el.type === 'image');

		const freeShipping = false; // todo: detect free shipping

		return (
			<div>
				<DocTitle title={selected.name}/>
				<div className='ProductDetail'>
					<div className='ProductDetail_main'>
						<h1 className='ProductDetail_name'>{selected.name}</h1>
						{
							selected.attributeValues.length ? (
								selected.attributeValues.map(x=><h3 key={x.id} className='ProductDetail_flavor'>{x.name}</h3>)
							)  : null
						}
					</div>
					<div className='ProductDetail_sub'>
						<div className='ProductDetail_price'>
							<Currency>{selected.price}</Currency>
						</div>
						{
							freeShipping ? (
								<div className='ProductDetail_free'>+ FREE SHIPPING</div>
							) : null
						}
						{
							selected.retailPrice ? (
								<div className='ProductDetail_retail-price'>
									<Currency>{selected.retailPrice}</Currency>
								</div>
							): null
						}
					</div>
				</div>
				<TwoColumn>
					<SideColumn>
						<Contents selector='h1, #main h2' ref='contents'/>
					</SideColumn>
					<MainColumn>
						<div id='main'>
							<div className='ProductFeature'>
								<div className='ProductFeature_photos'>
									<ProductPhotos images={images}/>
								</div>
								<div className='ProductFeature_body'>
									{
										selected._ext.highlightTitle ? (
											<div className='ProductFeature_highlight'>
												{selected._ext.highlightTitle}
											</div>
										) : (
											<EarliestDelivery productId={selected.id}/>
										)
									}
									{
										this.props.variableProducts.length > 1 ? (
											<div className='ProductFeature_flavor'>
												<div>Select {defaultAttributeName}:</div>
												<select onChange={this.selectProduct} value={selected.sku}>
													{
														this.props.variableProducts.map(el => {
															const name = el.attributeValues.map(el => el.name).join(' / ');

															return (
																<option disabled={el.quantityStatusCode === 'out-of-stock' ? true : false} key={el.id} value={el.sku}>{name}</option>
															);
														})
													}
												</select>
											</div>
										) : null
									}
									{
										selected.quantityStatusCode === 'out-of-stock' ? (
											<div>
												<Alert type='error'>Out of stock</Alert>
											</div>
										) : (
											<AddProduct addOns={this.props.addOns} tagline="Send the goodies to:" productId={selected.id} cart={this.props.cart} subscriptionPeriods={selected.subscriptionPeriods}/>
										)
									}
									<br/>
									<ProductAttributes product={selected}/>
								</div>
							</div>
							<hr/>
							<h2>Product Description</h2>
							<div dangerouslySetInnerHTML={{__html: selected.desc}}/>
							{
								this.props.products.length > 1 ? (
									<div>
										<hr/>
										<h2>{defaultAttributeName ? defaultAttributeName.substring(0,1).toUpperCase()+defaultAttributeName.substring(1) : 'Other'} options</h2>
										{
											this.props.variableProducts.map(el => <FlavorProduct key={el.id} cart={this.props.cart} product={el}/>)
										}
									</div>
								) : null
							}
							<hr/>
							<h2>Similar Items</h2>
							<TopSellers count={4} cursor={this.props.cursor.select('topsellers')}/>
						</div>
					</MainColumn>
				</TwoColumn>
			</div>
		);
	}
}));