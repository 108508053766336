import React from 'react';
import Currency from '../Currency';
import AddProduct from '../AddProduct';
import Link from '../Link';
import Alert from '../Alert';
import imgSrc from 'arcdynamic-resize';

export default React.createClass({
	render() {
		const el = this.props.product;
		const thumb = el.files.filter(el => el.type === 'image').shift();

		return (
			<div className='FlavorProduct'>
				<div className='FlavorProduct_body'>
					<div className='FlavorProduct_photo'>
						<Link to={arc.path.product+this.props.product.sku}>
							<img src={thumb ? imgSrc(thumb.url, 145) : null} width='145'/>
						</Link>
					</div>
					<div className='FlavorProduct_desc'>
						<h3 className='FlavorProduct_title'>
							<Link to={arc.path.product+this.props.product.sku}>{el.attributeValues.map((x) => x.name).join(' / ')}</Link>
						</h3>
						<div className='FlavorProduct_subtitle'>
							{el.name} - {el.retailPrice ? <Currency className='FlavorProduct_retail'>{el.retailPrice}</Currency> : null} <Currency className='FlavorProduct_price'>{el.price}</Currency>
						</div>
						<div className='FlavorProduct_buy'>
							{
								el.quantityStatusCode === 'out-of-stock' ? (
									<div>
										<Alert type='error'>Out of stock</Alert>
									</div>
								) : (
									<AddProduct productId={el.id} cart={this.props.cart}/>
								)
							}
						</div>
					</div>
				</div>
			</div>
		);
	},
});
