const queue = Object.create(null);
let id = 0;

export default (cursor)=>{
	let key = ++id;

	queue[key] = true;
	cursor.set(true);
	return {
		done() {
			delete queue[key];
			if (!Object.keys(queue).length) {
				cursor.set(false);
			}
		}
	};
};