import React from 'react';
import Link from '../Link';
import Currency from '../Currency';
import SquareImage from '../SquareImage';

export default ({products})=> (
	<div className='ProductGrid'>
	{
		products.map(el => {
			const thumb = el.files.filter(el => el.type === 'image').shift();

			return (
				<Link className='ProductGrid_item' key={el.sku} to={arc.path.base+'product/'+el.sku}>
					<div className='ProductGrid_img'>
						<SquareImage src={thumb ? thumb.url : null}/>
					</div>
					<div className='ProductGrid_body'>
						<div className='ProductGrid_name'>{el.name}</div>
						{
							el.attributeValues ? el.attributeValues.map(x => <div key={x.attributeId}>{x.name}</div>) : null
						}
						<div>
							<Currency className='ProductGrid_price'>{el.price}</Currency> {el.retailPrice ? <Currency className='ProductGrid_retail'>{el.retailPrice}</Currency> : null}
						</div>
					</div>
				</Link>
			);
		})
	}
	</div>
);
