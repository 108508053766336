export default (window)=> {
	return `Platform:
${window.navigator.platform}

UserAgent:
${window.navigator.userAgent}

AppVersion: 
${window.navigator.appVersion}

Viewport Size:
${window.innerWidth}x${window.innerHeight}
`
};