import React from 'react';
import Alert from '../Alert';
import cart from '../../cart';
import Control from 'react-control';
import Currency from '../Currency';
import CartReminder from '../CartReminder';
import AddOns from '../AddOns';
import svgClose from '../../svgs/close.svg';
import svgPlus from '../../svgs/plus.svg';
import Icon from '../Icon';
import request from 'arcdynamic-request';

export default React.createClass({
	getInitialState() {
		return {
			isBusy: null,
			errorMessage: false,
			orderIndex: '',
		};
	},
	addToCart(e) {
		e.preventDefault();

		if (this.state.orderIndex === 'someone-else') {
			const tempName = this.refs.tempName.value;

			if (!tempName) {
				this.refs.tempName.focus();
			} else {
				this.setState({isBusy: true});

				Promise.all([
					request(arc.path.store, {
						service: 'cart',
						action: 'store.Cart.createOrder',
						params: [null, tempName],
					}),
					cart.getCart(),
				]).then((values)=>{
					if (values[0].success && values[0].data && values[0].data.orderIndex && this.isMounted()) {
						this.setState({
							orderIndex: values[0].data.orderIndex,
						}, this.addProduct);
					} else {
						this.setState({
							isBusy: false,
							errorMessage: values[0].message,
						});
					}
				})
			}
		} else {
			this.setState({isBusy: true});
			this.addProduct();
		}
	},
	addProduct() {
		let orderIndex = this.state.orderIndex;
		if(!orderIndex && this.props.cart){
			Object.keys(this.props.cart.orders).every(key => {
				if(!this.props.cart.orders[key].name){
					orderIndex = this.props.cart.orders[key].orderIndex;
					return false;
				}
				return true;
			});
		}

		const subscriptionPeriodId = this.refs.subscribe ? this.refs.subscribe.value : null;

		if (subscriptionPeriodId) {
			cart.addSubscription({
				orderIndex: orderIndex,
				quantity: this.refs.quantity.value,
				id: this.props.productId,
				subscriptionPeriodId,
			}).then(res => {
				if (!this.isMounted()) return;

				if (res.success) {
					this.refs.reminder.open();

					this.setState({
						isBusy: null,
						errorMessage: false,
					})
				} else {
					this.setState({
						isBusy: null,
						errorMessage: res.message,
					})
				}
			});
		} else {
			cart.addProduct({
				orderIndex: orderIndex,
				quantity: this.refs.quantity.value,
				id: this.props.productId,
			}).then(res => {
				if (!this.isMounted()) return;

				if (res.success) {
					this.refs.reminder.open();

					this.setState({
						isBusy: null,
						errorMessage: false,
					})
				} else {
					this.setState({
						isBusy: null,
						errorMessage: res.message,
					})
				}
			});
		}

		if (this.props.addOns) {
			const ids = this.refs.addOns.getIds();
			if (ids) {
				Promise.all(ids.map(id => cart.addProduct({
					orderIndex: orderIndex,
					quantity: 1,
					id: id,
				}))).then(values => {
					if (this.isMounted() && this.props.addOns) {
						this.refs.addOns.reset();
					}
				});
			}
		}
	},
	render() {
		return (
			<div className='AddProduct'>
				<form onSubmit={this.addToCart}>
					{
						this.props.addOns ? <AddOns ref='addOns' products={this.props.addOns}/> : null
					}
					{
						this.props.subscriptionPeriods && this.props.subscriptionPeriods.length ? (
							<div className='AddProduct_subscribe'>
								<div>Subscription period:</div>
								<select ref='subscribe'>
									{
										this.props.subscriptionPeriods.map(el => <option key={el.subscriptionPeriodId} value={el.subscriptionPeriodId}>{el.subscriptionPeriod.name} - {Currency.format(el.price)}</option>)
									}
								</select>
							</div>
						) : null
					}
					{this.props.tagline ? <div>{this.props.tagline}</div> : null}
					<div className='AddProduct_recipient'>
						<div className={'AddProduct_select'+(this.state.orderIndex === 'someone-else' ? ' AddProduct_select--name' : '')}>
							<select ref='orderIndex' onChange={(e)=> this.setState({orderIndex: e.target.value})} value={this.state.orderIndex}>
								<option value=''>Myself</option>
								{
									this.props.cart ? Object.keys(this.props.cart.orders).map(key => this.props.cart.orders[key]).filter(el => el.name !== null).map(el => (
										<option key={el.orderIndex} value={el.orderIndex}>{el.name}</option>
									)) : null
								}
								<option value='someone-else'>Someone Else</option>
							</select>
							{
								this.state.orderIndex === 'someone-else' ? (
									<div className='AddProduct_name-field'>
										<input 
											placeholder='Recipient name' 
											autoFocus 
											required
											ref='tempName'
											type='text'
										/>
										<Control className='AddProduct_name-field_cancel' onClick={()=> this.setState({orderIndex: ''})}>
											<Icon svg={svgClose}/>
										</Control>
									</div>
								) : null
							}
						</div>
						<div className='AddProduct_io'>
							<input ref='quantity' type='number' defaultValue='1'/>
							<button data-is-busy={this.state.isBusy} type='submit'>
								<span className='AddProduct_btn'>
									<span className='AddProduct_icon'><Icon svg={svgPlus}/></span> Add to cart
								</span>
							</button>
						</div>
					</div>
				</form>
				{
					this.state.errorMessage ? (
						<div className='AddProduct_alert'>
							<Alert type='error'>{this.state.errorMessage}</Alert>
						</div>
					) : null
				}
				{
					this.state.orderIndex === 'someone-else' ? (
						<div className='AddProduct_alert'>
							<Alert type='warn'>Enter the name for your gift recipient, then click <em>Add to cart</em>.  You can add an address and gift message during checkout.</Alert>
						</div>
					) : null
				}
				<CartReminder ref='reminder' cart={this.props.cart}/>
			</div>
		);
	},
});
