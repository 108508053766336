import React from 'react';
import DocTitle from 'react-document-title';
import ContactForm from '../ContactForm';
import request from 'arcdynamic-request';
import Editable from '../Editable';
import {TwoColumn, MainColumn, SideColumn} from '../TwoColumn';
import Error404 from '../Error404';

export default React.createClass({
	getInitialState() {
		return {
			isBusy: false,
		};
	},
	componentWillMount() {
		this._getPage('contact', this.props.isAdmin);
	},
	_getPage(pageCode, isAdmin) {
		request(arc.path.store, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.getData',
			params: ['code', 'KKAPAGES', {
				filter: [
					{
						text: pageCode,
						code: 'PAGECODE',
						type: 'exact',
					},
				],
				limit: {
					count: 1,
				},
			}],
		}, {
			expires: isAdmin ? false : 1000*60*60,
		}).then((res)=>{
			if (!this.isMounted()) return;

			this.setState({
				page: res && res.success && res.data && res.data.length ? res.data[0] : false,
			});
		});
	},
	onSave(column, content) {
		if (this.state.isBusy) return;

		this.setState({isBusy: true});

		return request(arc.path.store, {
			service: 'arcimedes',
			action: 'open.datasource.table.Data.updateByTableCode',
			params: ['KKAPAGES', {
				value: [{
					[column]: content,
					PAGECODE: this.props.params.pageCode,
				}],
			}],
		}).then(()=>{
			// todo
			this.setState({isBusy: false});
		});
	},
	render() {
		let page = this.state.page;

		if (page === undefined) return null;

		if (page === false) {
			if (this.props.isAdmin) {
				page = {
					TITLE: '',
					MAIN: '',
					ASIDE: '',
				}
			} else {
				return <Error404/>;
			}
		}

		return (
			<TwoColumn>
				<DocTitle title={page.TITLE}/>
				<SideColumn>
					<Editable html={page.ASIDE} enabled={this.props.isAdmin} onSave={(html)=> this.onSave('ASIDE',html)}/>						
				</SideColumn>
				<MainColumn>
					<Editable tag='h1' html={page.TITLE} enabled={this.props.isAdmin} whitelist={{}} onSave={(html, text)=> this.onSave('TITLE',text)}/>
					<ContactForm/>
				</MainColumn>
			</TwoColumn>
		);
	}
});