import React from 'react';
import shallowEqual from 'react-pure-render/shallowEqual';
import Task from '../task';

export default (Component)=>{
	return React.createClass({
		propTypes: {
			cursor: React.PropTypes.object,
		},
		cursorData: undefined,
		isLoading: false,
		shouldComponentUpdate(nextProps) {
			if (nextProps.cursor && nextProps.cursor.get() !== this.cursorData) {
				this.cursorData = nextProps.cursor.get();
				return true;
			}
			return !shallowEqual(nextProps, this.props);
		},
		componentWillMount() {
			if (typeof Component.fetch === 'function' && this.props.cursor) {
				this.cursorData = this.props.cursor.get();

				// If the cursor already has data, that probably means it was fetched on the server and passed down as props on the client,
				// So skip fetching on the client if the cursor data exists.
				if (this.cursorData === undefined || (typeof this.cursorData === 'object' && !Object.keys(this.cursorData).length)) {
					const promise = Component.fetch(this.props);

					if (promise) {
						this.isLoading = true;
						let task = new Task(this.props.cursor.root().select('isLoading'));
						
						Promise.resolve(promise).then(values => {
							task.done();
							this.isLoading = false;
							if (this.isMounted()) {
								this.props.cursor.set(Object.assign({}, ...values));
							}
						});
					}
				}
			}
		},
		componentWillUnmount() {
			if (this.props.cursor !== undefined) {
				this.props.cursor.unset();
				this.props.cursor.root().tree.commit();
			}
		},
		render() {
			return !this.isLoading ? <Component {...this.props} {...this.cursorData}/> : null;
		}
	})
}