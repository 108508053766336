import React from 'react';
import {Link} from 'react-router';

export default React.createClass({
	propTypes: {
		onClick: React.PropTypes.func,
	},
	render() {
		return (
			<Link {...this.props} onClick={(e)=> {
				window.scrollTo(0, 0);
				if (this.props.onClick) {
					this.props.onClick(e);
				}
			}}/>
		);
	},
});
