import React from 'react';
import ReactiveWrapper from '../ReactiveWrapper';
import BigNav from '../BigNav';
// import MobileNav from '../MobileNav';
// <MobileNav categories={this.props.categories} search={this.props.search}/>

export default ReactiveWrapper(React.createClass({
	render() {
		return (
			<div className='MainNav'>
				<BigNav search={this.props.search}/>
			</div>
		);
	}
}));