import React from 'react';

export const TwoColumn = ({children})=> (
	<div className='TwoColumn'>{children}</div>
);

export const MainColumn = ({children})=> (
	<div className='TwoColumn_main'>{children}</div>
);

export const SideColumn = ({position, children})=> (
	<div className={'TwoColumn_side'+(position ? ' TwoColumn_side--'+position : '')}>{children}</div>
);