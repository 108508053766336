import React from 'react';
import request from 'arcdynamic-request';
import getOrdinal from '../../getOrdinal';
import months from '../../months';
import Icon from '../Icon';
import svgCalendar from '../../svgs/calendar.svg';
import svgPlane from '../../svgs/plane.svg';
import svgGift from '../../svgs/gift.svg';

export default React.createClass({
	render() {
		const product = this.props.product;
		const perishable = product.tags ? product.tags.filter(el => el.code === 'perishable').pop() : null;

		return (
			<div className='ProductAttributes'>
				{
					perishable ? (
						<div className='ProductAttributes_item'>
							<span className='ProductAttributes_icon'>
								<Icon svg={svgPlane}/>
							</span>
							<div className='ProductAttributes_msg'>Ships 2-Day Express Mail</div>
						</div>
					) : null
				}
				<div className='ProductAttributes_item'>
					<span className='ProductAttributes_icon'>
						<Icon svg={svgGift}/>
					</span>
					<div className='ProductAttributes_msg'>Add a custom gift-message to every order</div>
				</div>
				{
					product._ext.highlightIconA && product._ext.highlightTextA ? (
						<div className='ProductAttributes_item'>
							<div className='ProductAttributes_msg'>{product._ext.highlightTextA}</div>
						</div>
					) : null
				}
				{
					product._ext.highlightIconB && product._ext.highlightTextB ? (
						<div className='ProductAttributes_item'>
							<div className='ProductAttributes_msg'>{product._ext.highlightTextB}</div>
						</div>
					) : null
				}
				<div className='ProductAttributes_item'>
					<span className='ProductAttributes_icon'>
						<Icon svg={svgCalendar}/>
					</span>
					<div className='ProductAttributes_msg'>Choose your delivery date at checkout</div>
				</div>
				<div className='ProductAttributes_spacer'></div>
			</div>
		);
	},
});
