import React from 'react';
import Link from '../Link';
import Currency from '../Currency';
import imgSrc from 'arcdynamic-resize';

export default ({products, size = 90})=> (
	<div className='ProductList'>
		{
			products.map(el => {
				const images = el.files.filter(el => el.type === 'image');
				const src = images.length ? imgSrc(images[0].url, size) : null;

				return (
					<div key={el.sku} className='ProductList_item'>
						<Link className='ProductList_item_img' to={arc.path.base+'product/'+el.sku}>
							<img width={size} src={src}/>
						</Link>
						<div className='ProductList_item_body'>
							<div>
								<Link to={arc.path.base+'product/'+el.sku}>{el.name}</Link>
							</div>
							{
								el.attributeValues ? el.attributeValues.map(x => <div key={x.attributeId}>{x.name}</div>) : null
							}
							<div><Currency className='ProductList_price'>{el.price}</Currency> {el.retailPrice ? <Currency className='ProductList_retail'>{el.retailPrice}</Currency> : null}</div>
							<div><small>SKU: {el.sku}</small></div>
							{el.descBrief ? <div className='ProductList_desc'>{el.descBrief}</div> : null }
						</div>
					</div>
				);
			})
		}
	</div>
);
