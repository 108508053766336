import React from 'react';
import request from 'arcdynamic-request';
import ReactiveWrapper from '../ReactiveWrapper';

export default ReactiveWrapper(React.createClass({
	render() {
		return (
			<div className='Home'>
				<div className='Home_body'>
					{/*<div className='Home_hero'>
						<div className='Home_hero_cel1'>
							<img src={arc.path.base+'img/hero-1.jpg'}/>
							<div className='Home_hero_cel1_headline'>Taking care of you and your companions’ dietary needs, so you can focus on a speedy recovery!</div>
						</div>
						<div className='Home_hero_cel2'>
							<img src={arc.path.base+'img/hero-2.jpg'}/>
							<div className='Home_hero_cel2_headline'>Offering Meals For Breakfast, Lunch, Dinner & Shabbos</div>
						</div>
					</div>*/}
					<div className='Home_welcome'>
						<div className='Home_welcome_cel1'>
							{/*Delivery & Peace of Mind*/}
						</div>
						<div className='Home_welcome_cel2'>
							The Kosher Kare Anywhere website is currently under construction. 
							{/*Kosher Kare Anywhere is a non profit charity organization that provides 
							Glatt Kosher meals with a hemishe hashgacha free of charge to patients
							and their family members in areas that do not have access to kosher 
							food nearby.*/}
						</div>
						<div className='Home_welcome_cel3'></div>
					</div>
				</div>
			</div>
		);
	},
}));
