import React from 'react';
import Link from '../Link';
import Control from 'react-control';
import Icon from '../Icon';
import svgPlus from '../../svgs/plus.svg';
import svgMinus from '../../svgs/minus.svg';

export default React.createClass({
	getInitialState() {
		return {
			openCategories: {},
		};
	},
	toggleSubnav(categoryCode) {
		const openCategories = Object.assign({}, this.state.openCategories);

		if (openCategories.hasOwnProperty(categoryCode)) {
			delete openCategories[categoryCode];
		} else {
			openCategories[categoryCode] = true;
		}
		this.setState({
			openCategories,
		});
	},
	findCategories(categories, code, arr) {
		if (!categories.length || !code) return;

		let found = categories.filter(el => el.code === code).pop();

		if (found) {
			arr.push(found);
			return true;
		} else {
			found = categories.filter(el => this.findCategories(el.children, code, arr)).pop();

			if (found) {
				arr.push(found);
				return true;
			} else {
				return false;
			}
		}
	},
	setOpenCategories(categoryCode) {
		const found = [];
		const openCategories = {};
		this.findCategories(this.props.categories, categoryCode, found);
		found.forEach(el => {
			openCategories[el.code] = true;
		});
		this.setState({openCategories});
	},
	componentWillMount() {
		this.setOpenCategories(this.props.categoryCode);
	},
	componentWillReceiveProps(nextProps) {
		if (nextProps.categoryCode !== this.props.categoryCode) {
			this.setOpenCategories(nextProps.categoryCode);
		}
	},
	renderList(categories) {
		if (!categories) return null;

		return (
			<ul>
				{
					categories.map(el => {
						const isChecked = el.code === this.props.categoryCode;
						const isOpen = this.state.openCategories.hasOwnProperty(el.code);

						return (
							<li key={el.id}>
								<div className={'CategoryFilter_main '+(isChecked ? 'is-active' : '')}>
									<Link to={arc.path.base+'browse/'+el.code}>
										{el.name}
									</Link>
									{
										el.children && el.children.length ? (
											<Control className='CategoryFilter_toggle' onClick={()=> this.toggleSubnav(el.code)}><Icon svg={isOpen ? svgMinus : svgPlus}/></Control>
										) : null
									}
								</div>
								{el.children && el.children.length && isOpen ? this.renderList(el.children) : null}
							</li>
						);
					})
				}
			</ul>
		);
	},
	render() {
		return (
			<div className='CategoryFilter'>
				{this.renderList(this.props.categories)}
			</div>
		);
	},
});